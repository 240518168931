@use "sass:math";

//$base-font-size: 16; -- change to this after layout refactoring
$base-font-size: 10;

@function PxToRem($target, $context: $base-font-size) {
    @return math.div($target, $context) * 1rem;
}

@function PxToEm($target, $context: $base-font-size) {
    @return math.div($target, $context) * 1em;
}

// 1% of FullHD resolution height
$height-unit-modifier: 10.8;
// 1% of FullHD resolution width
$width-unit-modifier: 19.2;

@function HeightUnit($target, $context: $height-unit-modifier) {
    @return math.div($target, $context) * 1vh;
}

@function WidthUnit($target, $context: $width-unit-modifier) {
    @return math.div($target, $context) * 1vw;
}

// TODO: Remove this mixin / Replace with font mixins
@mixin textProp($fontSize, $fontWeight, $lineHeight, $letterSpacing: -0.02em) {
    font-size: $fontSize;
    font-weight: $fontWeight;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
}

@mixin fullSizeOverlay($background) {
    width: 100vw;
    height: 100vh;
    background: $background;
}
