/**
 * Fix for balloon toolbar when editor used in fixed containers.
 */
.ck.ck-balloon-panel {
    z-index: 99999 !important;
}

/**
 * Used when editor is used with black background
 */
.editor-inverse-colors {
    .ck.ck-content.ck-editor__editable {
        * {
            color: #FFFFFF;
        }
    }
}

div.ck-content {
    height: 100%;
}
