@import 'themes';

@import 'projects/web/ui-kit/ckeditor';
@import 'projects/web/ui-kit/ud-icons-font';
@import '../../packages/common/undock-theme/styles/theme';

@import '~angular-calendar/css/angular-calendar.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/* You can add global styles to this file, and also import other style files */

*, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
}

// this style for ck-editor field in meet>meeting-dock>notes.component
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
    border-color: transparent !important;
    box-shadow: none !important;
    padding: 0;
    border: none !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 18px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    p {
        margin: 0;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02em;

        &::before {
            color: #807A8E;
        }

        a {
            color: $white;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    a {
        cursor: pointer;
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    /**
     * Restores ability to interact with embedded media
     */
    &.ck-read-only {
        figure.media.ck-widget .ck-media__wrapper {
            div {
                pointer-events: auto;
            }
        }
    }
}

// fix for agenda notes link color
.meeting-notes .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners p a {
    color: $color-primary-main;
}

// fix for 'Videos inserted into Agenda can't play'
.ck-editor__editable:not(.ck-read-only) .ck-widget_selected .ck-media__wrapper > :not(.ck-media__placeholder) {
    pointer-events: initial !important;
}

.meeting-dock app-meet-notes .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners p {
    margin: 0;
}

app-meet-notes .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners p {
    color: $color-grayscale-black;
}

app-meet-private-notes .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners p::before {
    color: $color-grayscale-dark-1;
}

app-profile-meet-booking-expanded,
web-run-plan-calendar,
app-proposal-page-calendar,
app-dashboard-calendar-page,
app-dashboard-calendar-items,
app-meet-edit-event-page-calendar,
app-settings-schedule-edit-calendar {

    font-family: 'Inter', sans-serif;

    $eventLineHeight: 32px;
    $timeColumnWidth: 65px;
    $hourSegments: 4;
    $hourSegmentHeight: 32px; // this value should match block height in component

    @mixin halfHeight {
        /*transform: scaleY(0.5);*/
        // height: 0.5*$hourSegments*$hourSegmentHeight !important;
    }

    mwl-calendar-week-view {
        width: 100%;
        height: 100%;
        display: block;

        /**
         * Fix for current time marker
         */
        &.cal-day-view .cal-week-view {
            .cal-time-events {
                .cal-day-columns {
                    .cal-day-column {
                        app-timeline-week-current-time-marker {
                            width: calc(100% + 6px);
                            left: 65px;
                        }
                    }
                }
            }
        }
    }


    .cal-week-view {

        background: transparent;
        border-top: 0;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        height: 100%;

        .cal-time {
            width: $timeColumnWidth;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: $color-grayscale-dark;
            padding: 0;
            position: absolute;
            transform: translateY(-50%);
            background-color: $color-grayscale-light-4;
        }

        mwl-calendar-week-view-header {
            background: transparent;
            flex: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
        }


        //.cal-week-view .cal-hour-odd {
        //    background: inherit;
        //}

        .cal-day-headers {
            padding-left: 0;
            margin-left: $timeColumnWidth;
            //background: $color-grayscale-dark-2;
            border: none;
            display: grid;
            grid-gap: 1px;
            grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
            background: $color-grayscale-light-3;

            .cal-header {
                font-style: normal;
                font-size: 12px;
                line-height: 4px;
                padding: 9px;
                background: $color-grayscale-light-4;
                letter-spacing: 1px;
                text-transform: uppercase;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: $color-grayscale-dark;

                b {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;

                    //&.wide {
                    //    display: inline;
                    //}

                    //&.short {
                    //    display: none;
                    //}
                }

                br {
                    height: 4px;
                }

                span {
                    display: flex;
                    height: 36px;
                    width: 36px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 26px;
                    color: $color-grayscale-dark-2;
                    opacity: 1;
                }

                &.cal-past {
                    color: $color-grayscale-dark-3;
                }

                &.cal-today {
                    color: $color-grayscale-black;

                    span {
                        color: $white;
                        background: $color-primary-main;
                    }
                }

                &.cal-future {
                    color: $color-grayscale-dark-1;

                    span {
                        color: $color-grayscale-black;
                    }
                }

                &:first-child, &:last-child, &:not(:first-child), &:not(:last-child) {
                    border: none;
                }

                &:hover {
                    background: $color-grayscale-light-4;
                }
            }
        }

        .cal-all-day-events {
            background: $color-grayscale-light-3;
            display: grid;
            grid-gap: 1px;
            border: none;
            flex: 0;

            .cal-time-label-column {
                width: $timeColumnWidth;
            }

            .cal-events-row {
                height: 26px;
                margin-left: $timeColumnWidth;
            }

            .cal-time-label-column, .cal-day-column {
                background: $color-grayscale-light-4;
            }

            .cal-day-column {
                // hide for all
                border-color: transparent;

                & ~ .cal-day-column {
                    // show for any after same class (2th+)
                    border-color: $color-grayscale-light-2;
                }
            }
        }

        .cal-time-events {
            overflow: visible scroll;
            border: none;
            border-top: 1px solid $color-grayscale-light-2;

            &::-webkit-scrollbar {
                display: none;
            }

            .cal-event {
                line-height: 20px;
            }

            .cal-time-label-column {
                width: $timeColumnWidth;

                .cal-hour {
                    background: $color-grayscale-light-4;
                    border: none;

                    &:not(:first-child) {
                        border: none !important;
                    }

                    &:hover {
                        background-color: $color-grayscale-light-4;
                    }

                    mwl-calendar-week-view-hour-segment {
                        .cal-hour-segment {
                            display: flex;
                            justify-items: flex-end;
                            padding-right: 15px;
                            background: none;

                            &:hover {
                                background: none;
                            }
                        }
                    }
                }
            }

            .cal-resize-handle {
                z-index: 1;
            }

            .cal-day-columns {
                // background: $white;
                background: $color-grayscale-light-2;
                display: grid;
                grid-gap: 1px;
                // height: 100%;

                height: 1920px; // (24*SEGMENT_HEIGHT*SEGMENTS_COUNT)
                grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));

                .cal-day-column {

                    border: none;
                    max-height: 100%;
                    background-color: $color-grayscale-light-4;

                    .cal-hour {
                        background-color: transparent;
                    }

                    .cal-events-container {
                        .cal-event-container {
                            // margin-top: -0.5*$hourSegments*$hourSegmentHeight;
                            z-index: 5;
                            min-height: 20px;

                            mwl-calendar-week-view-event {
                                margin-left: 2px;
                                margin-right: 5%;
                                display: flex;
                                height: 100%;

                                app-timeline-week-day-event,
                                app-dashboard-calendar-week-view-event,
                                app-dashboard-calendar-week-view-new-event {
                                    .cal-event {
                                        background: none;
                                        border: none;
                                        color: $color-grayscale-black;
                                        padding: 0;
                                        margin: 0;
                                        width: 100%;
                                        height: 100%;
                                        position: relative;

                                        &.colored-event-container {
                                            padding: 6px;
                                            border: 2px solid transparent;
                                            border-radius: 0 8px 8px 0;
                                        }
                                    }


                                    &:not(.active) {
                                        .colored-event-container {
                                            border: none;
                                        }

                                        .cal-event {
                                            &:not(.colored-event-container) {
                                                padding: 8px;
                                            }
                                        }
                                    }

                                    &.active {
                                        .cal-event:not(.colored-event-container) {
                                            padding: 6px;
                                            border: 2px solid $color-grayscale-black;
                                        }
                                    }
                                }

                            }
                        }
                    }

                    &:first-child {
                        app-timeline-week-current-time-marker {
                            width: calc(100% + 6px);
                            left: -5px;
                        }
                    }

                    &:not(:first-child) {
                        .time-now-marker {
                            display: none;
                        }
                    }
                }

                .cal-hour:hover .cal-hour-segment {
                    // background: $color-grayscale-light-4;
                    background: inherit;
                }

                .cal-hour-segment, .cal-hour-segment:hover {
                    background: $color-grayscale-light-4
                }
            }

            mwl-calendar-week-view-current-time-marker + .cal-events-container + .cal-hour,
            .cal-hour:first-child, .cal-hour:last-child {
                mwl-calendar-week-view-hour-segment {
                    @include halfHeight;

                    .cal-hour-segment {
                        @include halfHeight;
                    }
                }
            }

            .cal-hour:first-child {
                .cal-time {
                    display: none;
                }
            }

        }

        .cal-hour:not(:last-child) .cal-hour-segment,
        .cal-hour:last-child :not(:last-child) .cal-hour-segment {
            // border-color: $white;
            border-color: transparent;
            border-bottom-style: solid;
        }

    }

    mwl-calendar-month-view {

        height: 100%;

        .cal-month-view {

            display: flex;
            height: 100%;
            flex-direction: column;
            background: transparent;

            .cal-cell-row {

                background: $color-grayscale-light-2;
                border: none;
                padding-bottom: 1px;

                height: 100%;
                display: grid;
                grid-gap: 1px;
                grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));

                &:hover {
                    background-color: $color-grayscale-light-2;
                }

                mwl-calendar-month-cell {
                    $showMoreHeight: 24px;
                    //$cellHeight: 5 * ( $eventLineHeight + 1px ) + $showMoreHeight;
                    $cellHeight: 6 * ( $eventLineHeight + 1px ) - 1px;

                    // max-height: $cellHeight;
                    // min-height: $cellHeight;

                    overflow: hidden;
                    background: $color-grayscale-light-4;

                    &.cal-day-cell.cal-weekend .cal-day-number {
                        color: $color-grayscale-dark;
                    }

                    &.cal-cell {

                        min-height: 24px;
                        color: $color-grayscale-dark;

                        .cal-day-number {
                            opacity: 1;
                        }

                        .more-items {
                            height: $showMoreHeight;
                        }

                        &:hover {
                            background: $color-grayscale-light-4;
                        }

                        &.cal-has-events.cal-open {
                            background: transparent;
                        }

                        &.cal-today {
                            color: $color-dark-purple;
                            background: $color-grayscale-light-4;

                            .cal-cell-content {
                                border: 2px solid $color-dark-purple;
                            }

                            .cal-day-number {
                                font-weight: 500;
                            }

                        }

                        &.cal-out-month .cal-day-number {
                            opacity: 0.5;
                        }

                    }

                    &:last-child, &:not(:last-child) {
                        border: none;
                    }

                }

                &.cal-header {

                    padding: 0px 1px 0 1px;

                    .cal-cell {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;

                        padding: 9px;
                        background: $white;
                        background: $color-grayscale-light-4;

                        letter-spacing: 1px;
                        text-transform: uppercase;
                        color: $color-grayscale-dark;
                    }
                }

            }

            .cal-days {

                display: flex;
                height: 100%;
                flex-direction: column;

                $timelineHeaderFilterHeight: 76px;
                $calendarTopDaysLine: 32px; // @todo validate this statement for other resolutions
                /*
                max-height: calc(100vh - #{$timelineHeaderFilterHeight + $calendarTopDaysLine});
                overflow: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
                */

                & > div {
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    flex: 1 1 0;
                }

                .cal-cell-row {
                    border: none;

                    display: flex;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                    flex: 1 1 0;

                }

                & > div:last-child {
                    .ca-cell-row {
                        mwl-calendar-month-cell {
                            border-bottom: 1px solid $white;
                        }
                    }
                }

            }

        }

    }

    .timeline-display-style-selector .mat-button-toggle-button .mat-button-toggle-label-content {
        line-height: 22px;
        padding: 9px 16px;
    }

}

@media all and (max-width: 768px) {

    app-dashboard-calendar-items {
        .cal-week-view {
            .cal-day-headers {
                .cal-header {
                    b {
                        //&.wide {
                        //    display: none;
                        //}
                        //
                        //&.short {
                        //    display: inline;
                        //}
                    }
                }
            }
        }
    }
}

@media all and (max-width: 480px) {

    app-account-privacy-control-settings .availability-limit-box mat-slide-toggle .mat-slide-toggle-label {
        flex-direction: row-reverse;
        width: 100%;

        .mat-slide-toggle-bar {
            margin: 0 0 auto auto;
        }
    }

    app-account-time-zone-settings mat-slide-toggle {
        width: 100%;

        .mat-slide-toggle-label {
            .mat-slide-toggle-bar {
                margin-top: -2px !important;
                margin-right: 24px;
            }

            .mat-slide-toggle-content {
                white-space: normal;
            }
        }
    }

    .cal-week-view .cal-time-events .cal-events-container {
        max-width: calc(100% - 60px);
        margin: 0 0 0 auto;
    }

    app-proposal-page-calendar .cal-week-view mwl-calendar-week-view-header {
        display: none;
    }
}


/*______________________*/
/*____material tabs______*/
/*______________________*/

.mobile-tabs-wrapper {

    mat-tab-group {

        .mat-tab-label .mat-tab-label-content {
            width: 100%;
            height: 100%;
            padding: 0;

            .mat-icon {
                width: 100%;
                height: 100%;
                max-height: 40px;
            }
        }

        .tab-icon .profile-icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            max-height: 40px;
        }


        .mat-tab-label {
            width: 100%;
            min-height: 40px;
            height: auto;
        }

        mat-tab-header {
            background: $color-grayscale-light-4;
            border-bottom-color: $color-grayscale-light-2;
        }

        .mat-tab-body-content {
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}

app-settings-page {
    mat-tab-group {
        height: 100%;
        position: relative;

        mat-tab-header {
            width: 100%;
            background: #FFFFFF;
            //position: fixed !important;
            z-index: 1;
        }

        .mat-tab-body-wrapper {
            //padding-top: 50px;
            height: 100%;
            z-index: 17;
            //min-height: 100vh;
        }
    }
}

@mixin arrowBackground {
    background: url('data:image/svg+xml;utf8,<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5 4L9 1" stroke="rgb(43, 39, 51)" stroke-width="2" stroke-linecap="round"/></svg>') no-repeat center center;
    //filter: invert(18%) sepia(11%) saturate(6846%) hue-rotate(229deg) brightness(97%) contrast(90%);
    // https://codepen.io/sosuke/pen/Pjoqqp -- filter generator
    transition: rotate .5s linear;
}

@mixin arrowBackgroundWhite {
    background: url('data:image/svg+xml;utf8,<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5 4L9 1" stroke="rgb(255, 255, 255)" stroke-width="2" stroke-linecap="round"/></svg>') no-repeat center center;
    //filter: invert(18%) sepia(11%) saturate(6846%) hue-rotate(229deg) brightness(97%) contrast(90%);
    // https://codepen.io/sosuke/pen/Pjoqqp -- filter generator
    transition: rotate .5s linear;
}

mat-select.customArrow {

    .mat-select-arrow-wrapper {
        @include arrowBackground;
    }

    .mat-select-arrow {
        opacity: 0;
    }

    &[aria-expanded='true'] .mat-select-arrow-wrapper {
        transform: rotate(180deg);
    }

    &.__white {
        .mat-select-arrow-wrapper {
            @include arrowBackgroundWhite;
        }
    }
}

mat-select-trigger.customArrow {
    @include arrowBackground;
    padding-right: 20px;
}

// styling of ' Minimum notice required for scheduling ' select _ Settings > Preferences
.cdk-overlay-pane .mat-option.notice-mat-option {
    height: 48px !important;
}

mat-radio-button, mat-checkbox {
    &.orange {
        &.mat-checkbox-checked {
            .mat-checkbox-background {
                background: $dark-color-primary !important;
            }
        }
    }
}

.account-settings-time-zone-container .mat-select .mat-select-trigger .mat-select-value,
.title-row.-with-icon .mat-select .mat-select-trigger .mat-select-value {
    //max-width: 0;
    // it crashed select in settings > account
}

app-profile-meeting-duration-select .mat-select .mat-select-trigger .mat-select-value {
    //max-width: 0;
    // it crashed select in profile

}

app-meet-edit-duration-select .mat-select .mat-select-trigger .mat-select-value {
    //max-width: 110px;
}

app-dashboard-header .mat-select {
    //width: auto;
    .mat-select-trigger .mat-select-value .mat-select-value-text {
        margin-right: 16px;
    }
}

.main-container mat-tab-group mat-tab-header {
    background: #FFFFFF;
}

/*____MATERIAL MENU in settings > calendars > add new calendar______*/
.cdk-overlay-pane .mat-menu-panel.mat-menu-connect .mat-menu-content {
    min-width: 280px;
    box-shadow: 0px 10px 25px rgba(109, 101, 132, 0.2);
    border-radius: 10px;

    .connect-calendar-button {
        position: relative;
        width: 100%;
        max-width: 370px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 13px 15px;
        border-radius: 10px;
        border: none;

        &:hover {
            background: $color-grayscale-light-3;
        }

        &.google {
        }

        &.microsoft {
        }

        .icon-box {
            width: 22px;
            height: 22px;
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;
        }

        img {
            width: 18px;
        }

        .text {
            font-family: Inter, sans-serif;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: $color-grayscale-black;
        }
    }
}

/*____MATERIAL MENU in settings > calendars > calendar settings ______*/
.cdk-overlay-pane .mat-menu-panel.calendar-menu .mat-menu-content {
    .calendar-settings-dropdown {

        mat-slide-toggle {
            height: unset;
            min-width: 248px;
            padding: 9px 12px;
        }

        .mat-slide-toggle-label {
            white-space: nowrap;
            font-size: 15px;
            line-height: 20px;
            color: $color-grayscale-black;
        }

        .calendar-settings-menu-item {
            display: flex;
            align-items: center;
            min-width: 248px;
            white-space: nowrap;
            padding: 9px 12px;
            font-size: 15px;
            line-height: 20px;
            cursor: pointer;
            color: $color-grayscale-black;

            i.material-icons {
                color: #bec9e1;
                font-size: 15px;
                margin-left: 5px;
            }

            &.delete {
                color: $color-alert-error;
            }

            &.delegate {
                color: #bec9e1;
                cursor: not-allowed;
            }
        }
    }
}

/*____MATERIAL MENU in settings > calendars > sub-calendar______*/
.cdk-overlay-pane .mat-menu-panel.toggle-visibility .mat-menu-content {
    min-width: 246px;
    padding: 12px;

    mat-slide-toggle {
        margin-right: 12px;

        p {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.02em;
            color: $color-grayscale-black;
        }
    }
}

//__________mat-menu Schedules > schedule type
.cdk-overlay-pane .mat-menu-panel.schedule-type .mat-menu-content .mat-menu-item {
    display: flex;
    align-items: center;
    padding-left: 12px;

    .option {
        font-family: Inter, sans-serif;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: $color-grayscale-black;

        &.-alert-feature {
            color: $color-alert-error;
        }
    }
}

/*____MATERIAL MENU in Agenda > Participants sidebar ______*/
.cdk-overlay-pane .mat-menu-panel .mat-menu-item.-dock-participants {
    padding: 0 8px;
}

/*____MATERIAL MENU radio button > Proposal confirmation ______*/
.proposal-block.response-slot mat-radio-button.mat-radio-button .mat-radio-label-content {
    padding-left: 0;
}


/*____________________________*/
/*____________________________*/
/*____MATERIAL PAGINATOR______*/
/*____________________________*/

.mat-paginator {
    background: transparent;
}

.meeting-dock-container__option-button .mat-badge-content {
    background-color: $primary-color;
}

/*____________________________*/
/*____________________________*/
/*____MATERIAL THEMING______*/
/*____________________________*/
::ng-deep .mat-snack-bar-container.ud-snackbar--primary {
    background: $color-dark-purple !important;
}

//.mat-snack-bar-container.ud-snackbar--primary {
//    background: $color-dark-purple !important;
//}

::ng-deep .mat-snack-bar-container.ud-snackbar--secondary {
    background: $color-grayscale-dark !important;
}

//.mat-snack-bar-container.ud-snackbar--secondary {
//    background: $color-grayscale-dark !important;
//}

::ng-deep .mat-snack-bar-container.ud-snackbar--info {
    background: $color-info !important;
}

//.mat-snack-bar-container.ud-snackbar--info {
//    background: $color-info !important;
//}

::ng-deep .mat-snack-bar-container.ud-snackbar--success {
    background: $color-success !important;
}

//.mat-snack-bar-container.ud-snackbar--success {
//    background: $color-success !important;
//}

::ng-deep .mat-snack-bar-container.ud-snackbar--danger {
    background: $color-alert-error !important;
}

.mat-snack-bar-container.ud-snackbar--danger {
    background: $color-alert-error !important;

    .mat-button-wrapper {
        color: #FFFFFF !important;
    }
}


.mat-autocomplete-panel {
    @include purple-scrollbar;
}

.mat-tab-body-content {
    @include purple-scrollbar;
}

body.modal-open {
    overflow: hidden;
}

simple-modal-holder {
    .modal {
        display: block;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        padding: 15px;
        z-index: 1025;
        background: rgba(18, 17, 20, 0.5);

        &.fade-anim {
            transition: opacity 400ms ease-in-out;
            will-change: opacity;
            opacity: 0;
        }

        &.fade-anim.in {
            opacity: 1;
        }

        .modal-content {
            will-change: opacity;
            display: inline-block;
            text-align: left;
            position: relative;
            top: 50%;
            left: 50%;

            max-width: calc(100vw - 20px);
            max-height: calc(100vh - 20px);

            transform: translateY(-50%) translateX(-50%);
            transition: opacity 400ms ease-in-out, width .3s linear, height .3s linear;

            // @todo move to player-component SCSS when it will be created
            .floating-player {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                position: relative;
                background: $color-grayscale-black;
                border-radius: 24px;
                overflow: hidden;
                transition: width .3s linear, height .3s linear;

                .close-button {
                    position: absolute;
                    z-index: 2;
                    top: 12px;
                    right: 12px;
                    width: 40px;
                    height: 40px;
                    outline: none;
                    cursor: pointer;
                    border: none;
                    border-radius: 50%;
                    display: flex;
                    background: rgba(128, 128, 128, 0.1);
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        background: rgba(128, 128, 128, 0.25);
                    }
                }

                video {
                    object-fit: cover;
                    cursor: move;
                    width: 100%;
                    max-width: 800px;
                    height: 100%;
                    max-height: 500px;
                    outline: none;
                }

                .controls-bar {

                    display: flex;
                    width: 100%;
                    background: #fff;
                    padding: 20px;
                    align-items: center;
                    position: relative;
                    justify-content: space-between;

                    .title {
                        // width: 100%;
                        // max-width: 200px;
                        max-width: 650px;
                        text-align: left;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        color: $color-grayscale-black;

                        font-family: Inter, sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        line-height: 26px;
                        letter-spacing: -0.02em;

                        &.short {
                            max-width: 35%;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .video-delete-btn, .stop-recording-btn, .record-settings {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        outline: none;
                        border: 1px solid $color-grayscale-light-2;
                        border-radius: 8px;
                        background: transparent;
                        cursor: pointer;
                        box-sizing: border-box;
                        font-family: Inter, sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        background: none;
                        white-space: nowrap;
                    }

                    .video-delete-btn {
                        padding: 0 8px;
                        border-width: 2px;
                        line-height: 34px;
                        color: $color-alert-error;
                    }

                    .stop-recording-btn {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 40px;
                        min-width: 120px;
                        margin: 0 auto;
                        padding: 10px 12px;
                    }

                    .record-settings {
                        width: 40px;
                        height: 40px;
                        cursor: no-drop;

                        .material-icons {
                            color: $primary-color;
                        }
                    }

                    .recording-timer {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        top: -38px;
                        left: 0;
                        width: 100%;
                        position: absolute;

                        span {
                            color: #ffffff;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }

        .modal-footer,
        .modal-header {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            height: 56px;
            padding: 0 16px;
        }

        .modal-header {
            border-bottom: 1px solid #cecece;
        }

        .modal-body {
            padding: 16px;
        }

        .modal-footer {
            border-top: 1px solid #cecece;
        }

    }
}

//______________button hover effects
.btn-primary {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: $white;
    background: $color-primary-main;
    border: none;
    cursor: pointer;

    &:hover {
        background: $color-primary-dark-2 !important;
    }

    &:active {
        background: $color-primary-light-2 !important;
    }

    &:disabled {
        color: $color-grayscale-light-2 !important;
        background: $color-grayscale-light-1 !important;
    }
}

.btn-secondary {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: $color-primary-main;
    background: $white;
    border: 1px solid $color-grayscale-light-2;
    cursor: pointer;

    &:hover {
        background: $color-grayscale-light-3 !important;
    }

    &:active {
        background: $color-grayscale-light-1 !important;
    }

    &:disabled {
        color: $color-grayscale-light-2 !important;
    }
}

.clickable {
    cursor: pointer;
}

.hot-key {
    @include label11Medium;
    display: inline-flex;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 4px;

    color: $color-grayscale-dark-1;
    background: $color-grayscale-light-2;

    &.volumetric {
        ::before {
            border-radius: 0 0 9px 9px;
            bottom: -3px;
            left: 0;
            content: " ";
            position: absolute;
            width: 100%;
            height: 12px;
            z-index: -1;
            background-color: #CECADA;
        }
    }

    &.bordered {
        border: 1px solid $color-grayscale-light-1;
    }

    &.dark {
        border: none;
        color: $color-grayscale-white;
        background: $color-primary-light-2;
        &.bordered {
            border: 1px solid $color-primary-light-2;
        }
    }

    &.large {
        @include label12Medium;
        padding: 3px 6px;
        margin-left: 8px;
    }

    app-icon-special-key {
        display: inline;
        margin-right: 2px;
    }
}

.vertical-items-list {
    display: flex;
    flex-direction: column;

    .sub-title {
        @include fontCaption12;
        margin: 24px 0 12px;
        color: $color-grayscale-dark-1;
    }

    .list-item {
        display: flex;
        align-items: center;
        justify-content: stretch;

        &:not(:first-child) {
            margin-top: 14px;
        }

        &:last-child {
            margin-bottom: 14px;
        }

        .image-box {
            width: 48px;
            height: 48px;
            overflow: hidden;
            border-radius: 12px;
            background-color: $color-grayscale-light-3;

            &.icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: 100%;
                height: 100%;
                background-color: $color-grayscale-white;
            }
        }

        .content-box {
            flex-grow: 1;
            margin-left: 12px;

            .label {
                @include fontBody15Regular;
                color: $color-grayscale-black;
            }

            .description {
                @include label12Regular;
                margin-top: 2px;
                color: $color-grayscale-dark-1;
            }

            .tags {
                display: inline-flex;
                margin-left: 4px;

                .tag {
                    @include badge;
                    @include textProp(10px, 700, 14px);
                    background: rgba($color-grayscale-dark-4, .2);

                    &.primary {
                        color: $color-complementary-gold-dark-1;
                        background: rgba($dark-color-primary, 0.1);
                    }
                }
            }
        }

        .actions-list {
            display: flex;
            margin-right: 6px;

            button.invite {
                @include buttonSmall(
                                $color-primary-main,
                );

                &[disabled] {
                    opacity: .8;
                    color: $color-grayscale-light-1;
                }
            }
        }
    }
}

.chips-list {
    display: flex;

    .chip-item {
        @include fontBody14Regular;

        display: inline-flex;
        align-items: center;

        height: 32px;
        border-radius: 44px;
        color: $color-grayscale-black;
        border: 1px solid $color-grayscale-light-2;

        span {
            margin-left: 10px;
        }

        app-icon-close {
            margin: 5px;
        }
    }
}

.checklist-container {
    display: flex;
    flex-direction: column;

    .checklist-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 0;

        span.text {
            @include fontBody14Regular;
            margin-left: 12px;
            color: $color-grayscale-white;
        }
    }
}

@keyframes pulse {
    0% {
        opacity: 0.25;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.25;
    }
}

//_________________user-avatar-cropper
image-cropper {
    div {
        .overlay {
            width: auto;
            margin: 0 auto;
            background: rgba(18, 17, 20, 0.5);
        }

        .cropper {
            width: 100%;
            height: 100%;
            border-radius: 60px;
        }
    }
}

@media all and (max-width: 768px) {
    simple-modal-holder {
        .modal .modal-content .floating-player .controls-bar {
            justify-items: flex-end;

            .stop-recording-btn {
                position: relative;
                left: auto;
                transform: none;
                margin: 0 5px;
            }

            .title {
                justify-self: flex-start;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

