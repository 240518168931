@import "projects/web/ui-kit/utils";
@import "projects/web/ui-kit/theme";

$nb-enable-css-custom-properties: true;

.cdk-global-scrollblock {
    // disabled scroll page when use <select>
    overflow: hidden;
    top: 0 !important;
}

.tabset {
    top: -44px;
    position: absolute;

    .tab {
        &.active {
            a::before {
                //background-color: $primary-color;
            }
        }

        a {
            outline: none;

            span {
                text-transform: capitalize;
                font-family: 'Inter', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

.menu-item a .menu-title {
    text-transform: uppercase;
    font-size: 1.1rem;
}

.fc-event-container .fc-time-grid-event {
    border-bottom: 2px solid $color-grayscale-dark-1;
}

/* NG Material */

.mat-select {
    font-family: 'Inter', sans-serif !important;
    font-weight: normal;

    .mat-select-trigger {
        width: 100%;
        height: auto;

        .mat-select-value {
            max-width: unset;
            color: $color-grayscale-black;
        }
    }

    &.mat-select-disabled {
        background-color: $color-grayscale-light-3 !important;

        .mat-select-trigger {
            background-color: $color-grayscale-light-3;

            .mat-select-value {
                color: $color-grayscale-dark-1;
            }

            .mat-select-arrow-wrapper {
                opacity: 0.5;
            }
        }
    }
}

// Conference audio/video settings select
.media-devices-selector__mat-select-box .mat-select .mat-select-trigger {
    line-height: 48px;
}

.timezone-description .mat-select-value, .time-zone-selector-container .mat-select-value {
    max-width: unset;
    width: auto;
}

.selected-meeting-date-box .mat-select {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: $color-grayscale-black;

    .mat-select-trigger {
        .mat-select-value {
            max-width: unset;
        }

        .mat-select-arrow-wrapper {
            display: none;
        }
    }
}

.selected-meeting-date-box .timezone-selector-new .mat-select .mat-select-trigger .mat-select-arrow-wrapper {
    display: inherit;
}

.cdk-overlay-pane {
    .mat-select-panel {
        @include purple-scrollbar;

        .mat-option.mat-selected:not(.mat-option-multiple) {
            background: $color-grayscale-light-3;
        }
    }
}

// material toggle

mat-slide-toggle.mat-slide-toggle {
    .mat-slide-toggle-bar {
        width: 40px;
        height: 24px;
        border-radius: 40px;
        background: $color-grayscale-light-1;

        .mat-slide-toggle-thumb-container {
            width: 16px;
            height: 16px;
            top: 50%;
            left: 4px;
            transform: translateY(-50%);

            .mat-slide-toggle-thumb {
                width: 16px;
                height: 16px;
            }
        }
    }

    &.mat-checked {

        .mat-slide-toggle-thumb-container {
            transform: translate(16px, -50%);
        }

        .mat-slide-toggle-bar, .mat-ripple-element {
            background-color: $primary-color;
        }

        .mat-slide-toggle-thumb {
            background-color: #FFFFFF;
        }
    }
}

.mat-button {
    overflow: hidden !important;

    .mat-button-ripple.mat-ripple, .mat-button-focus-overlay {
        border-radius: 0;
    }
}

button.mat-button.-light-bg:hover {
    background: $color-grayscale-light-4;

    &:not(.mat-button-disabled) .mat-button-focus-overlay {
        opacity: 0;
    }
}

.cdk-overlay-pane {

    .mat-elevation-z4 {
        box-shadow: none;
    }

    .mat-menu-panel {
        border: none;
        box-shadow: 0 10px 20px rgba(149, 139, 177, 0.15);
        background: $white;
        border-radius: 10px;

        .mat-menu-item {
            font-size: 15px;
            border-radius: 6px;
            padding: 0;

            &:hover, &:focus-visible {
                background: $color-grayscale-light-3;
            }

            &:active {
                background: $color-grayscale-light-1;
            }

            &.mat-menu-item-submenu-trigger {
                padding-right: 32px;
            }
        }

    }

    .mat-select-panel {
        //hotfix for ios
        min-width: 88px;

        .mat-option {
            height: 40px;

            .mat-icon {
                margin-right: 0;
            }
        }
    }

    .mat-option {
        padding: 0;

        &:hover:not(.mat-option-disabled), &:focus:not(.mat-option-disabled) {
            background: $color-grayscale-light-3;
        }

        &.mat-option-disabled {
            opacity: 0.3;
        }

        .mat-option-text {
            padding: 0 16px;
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: $color-grayscale-black;
            @include defaultFont;
        }
    }
}

button.mat-menu-item {
    position: relative;
    z-index: 1;
    height: auto;

    &:empty {
        display: none;
    }

    &:hover:not([disabled]), &.cdk-program-focused:not([disabled]),
    &.cdk-keyboard-focused:not([disabled]), .mat-menu-item-highlighted:not([disabled]) {
        background: $color-grayscale-light-3;
    }

    .material-menu-item-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        color: $color-grayscale-black;
        line-height: 1;
        padding: 8px;

        &.-rsvp {
            justify-content: space-between;

            &:after {
                position: absolute;
                z-index: 2;
                bottom: 0;
                left: 0;
                content: "";
                width: 100%;
                border-bottom: 1px solid $color-grayscale-light-2;
            }

            span {
                font-weight: normal;
                font-size: 15px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: $color-grayscale-dark;
            }
        }

        &.-delete {
            color: $color-alert-error;
        }

        &.-header-mobile-view {
            display: none;
        }

        .item-icon {
            margin-right: 12px;
            color: #807A8E;
        }
    }
}

.cdk-overlay-pane .mat-menu-panel {
    border-radius: 8px;
    min-height: auto;
}

.cdk-overlay-pane .mat-menu-panel .mat-menu-content {
    min-width: 170px;
    padding: 4px;
}

.event-details .mat-tab-header {
    border-bottom: none;
}

/*____MATERIAL radio button______*/
mat-radio-button.mat-radio-button .mat-radio-label-content {
    padding-left: 16px;
}

mat-radio-button.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary-color;
}

mat-radio-button.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
    background: $primary-color;
}

mat-radio-button.mat-radio-button.mat-accent .mat-radio-inner-circle, mat-radio-button.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), mat-radio-button.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, mat-radio-button.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background: $primary-color;
}


/*____MATERIAL checkbox______*/
mat-checkbox.mat-checkbox {
    -webkit-tap-highlight-color: transparent !important;

    &.round {
        max-width: 20px;
        border-radius: 50%;
        .mat-checkbox-frame {
            border-radius: 50%;
        }
        .mat-checkbox-background {
            border-radius: 50%;
        }
    }

    &.cdk-focused {
        .mat-ink-ripple {
            background-color: rgba(0, 178, 0, .26);
        }
    }

    .mat-checkbox-frame {
        border-color: $color-grayscale-light-1;
        border-radius: 4px;
    }

    &.mat-checkbox-checked .mat-checkbox-checkmark {
        width: 12px;
        height: 12px;
        margin: 2px auto;
    }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: $primary-color !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: $primary-color !important;
    border-radius: 4px;
}

/*____MATERIAL Tooltips______*/

.mat-tooltip-panel .mat-tooltip {
    max-width: 291px;
    padding: 6px 8px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    background-color: $color-grayscale-black;
    border-radius: 4px;
}

/*____MATERIAL notifications______*/
.cdk-overlay-pane snack-bar-container {

    &.mat-snackbar-container {
        padding: 14px 16px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 10px 20px rgba(149, 139, 177, 0.15);
        background: $color-grayscale-black;
    }

    //&.ud-snackbar--primary {
    //    background: $primary-color;
    //}
    //
    //&.ud-snackbar--secondary {
    //    background: $color-grayscale-dark;
    //}
    //
    //&.ud-snackbar--success {
    //    background: $color-success;
    //}
    //
    //&.ud-snackbar--warning {
    //    background: $color-warning;
    //}
    //
    //&.ud-snackbar--danger {
    //    background: $color-alert-error;
    //}
    //
    //&.ud-snackbar--info {
    //    background: $color-info;
    //}
}

/*____MATERIAL autocomplete (confirmation>participants-list)______*/

.mat-autocomplete-panel {
    border-radius: 4px;
}

/*____MATERIAL TABS______*/

mat-tab-group {

    .mat-tab-body {
        font-family: "Inter", sans-serif;
    }

    .mat-tab-label {
        width: auto;
        padding: 0;
        opacity: 1;

        .mat-tab-label-content {
            padding: 15px 16px 13px;
            font-family: Inter, sans-serif;
            letter-spacing: -0.02em;
        }
    }

    .mat-focus-indicator {
        color: $primary-color;
    }

    .mat-tab-label-active {
        color: $primary-color;

        div mat-icon {
            svg path, svg g path, svg g rect {
                fill: $primary-color;
            }
        }
    }
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $primary-color !important;
}

.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none !important;
}

@media all and (max-width: 900px) and (min-resolution: 1.25dppx) {

    .onboarding-container {

        .mat-horizontal-stepper-header-container {
            max-width: 310px;
            margin: 0 auto;
        }

        .mat-step-text-label {
            display: none;
        }

    }

}

@media all and (max-width: 480px) {

    button.mat-menu-item {
        padding: 0;

        .material-menu-item-box {
            &.-header-mobile-view {
                display: flex;
            }
        }
    }
}
